.text-animate {
    display: inline-block;
    opacity: 0;
    animation: bounceIn 1s 1s;
    animation-fill-mode: forwards;
    min-width: 10px;
}

.text-animate-hover {
    min-width: 10px;
    display: inline-block;
    animation-fill-mode: both;
}

.text-animate-hover:hover{
    animation: rubberBand 1s !important;
    color: #a97dff !important; 
    cursor: default !important;
}

@for $i from 1 through 39 {
    .text-animate._#{$i} {
        animation-delay: #{calc($i / 10)}s;
    }
}