.stage-cube-cont {
    width: 55%;
    height: 100%;
    top: 0;
    padding-top: 13.5%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 18s;
    animation-delay: 3s;
    transform-style: preserve-3d;
    transform-origin: 150px 150px 0;
    margin-left: calc(50% - 150px);

    div {
        position: absolute;
        width: 350px;
        height: 350px;
        border: 2px solid #ccc;
        background: rgb(255 255 255 / 40%);
        text-align: center;
        font-size: 175px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 35px 0 lightblue;
    }

    .face1 {
        transform: translateZ(175px);
    }

    .face2 {
        transform: rotateY(90deg) translateZ(175px);
    }

    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(175px);
    }

    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(175px);
    }

    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(175px);
    }

    .face6 {
        transform: rotateX(-90deg) translateZ(175px);
    }
}

@keyframes spincube {
    0%,

    100% {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    16% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }

    33% {
        transform: rotateY(-90deg) rotateX(90deg);
    }

    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }
    
    83% {
        transform: rotateX(90deg);
    }
}