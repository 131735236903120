$primary-color: #A97DFF;

@import url('animate.css');
@import url('~loaders.css/src/animations/pacman.scss');

.loader-active {
  display: block;
  position: absolute;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}