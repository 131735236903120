.home-page {
    .text-zone {
        position: absolute;
        left: 15%;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 67px;
        margin: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;

        img {
            width: 50px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s; 
        }

        .logo-repl {
            color: #a97dff;
        }
    }

    h2 {
        color: #808080;
        margin-top: 20px;
        font-weight: 400;
        font-size: 13px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #a97dff;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #a97dff;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #a97dff;
            color: #333;
        }
    }

    .logo-container {
        position: absolute;
        right: 0;
        width: 40%;
        height: 100%;
        overflow: hidden;

        .solid-logo {
            position: absolute;
            right: 0;
            top: 20%;
            width: 90%;
            transform: rotateZ(30deg);
            animation: fadeIn;
        }
    }
}